<template>
  <div class="modal-overlay">
    <div class="modal-content">
      <div class="spinner">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="loading-text">Tunggu Sebentar...</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingModal',
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Jika Anda ingin membuatnya benar-benar transparan, Anda bisa gunakan: */
.modal-content {
  background: rgba(255, 255, 255, 0); /* Membuat latar belakang sepenuhnya transparan */
  padding: 40px;
  border-radius: 10px;
  max-width: 300px;
  width: 100%;
  text-align: center;
}

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.circle {
  width: 15px;
  height: 15px;
  margin: 5px;
  border-radius: 50%;
  background-color: #69da6f;
  animation: bounce 1.4s infinite ease-in-out both;
}

.circle:nth-child(1) {
  animation-delay: -0.32s;
}

.circle:nth-child(2) {
  animation-delay: -0.16s;
}

.circle:nth-child(3) {
  animation-delay: 0s;
}

.loading-text {
  font-size: 20px;
  margin-top: 10px;
  color: #69da6f;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
</style>