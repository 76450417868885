<template>
    <v-card class="mt-4 mx-2 elevation-0">
        <FtRegistrasiLayananGroupMainTable/>
    </v-card>
  </template>
  
  <script>
  import FtRegistrasiLayananGroupMainTable from "@/components/admin_registrasi_layanan/FtRegistrasiLayananGroupTable.vue";
  
  export default {
    name: "RegistrasiLayananGroupTableView",
    components: {
      FtRegistrasiLayananGroupMainTable
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
  
    },
  
  
  }
  </script>
  
  <style scoped>
  </style>